define("ember-svg-jar/inlined/navigation-draft-update-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.753 1.4h21.042c.969 0 1.753.785 1.753 1.753v25.494c0 .968-.784 1.753-1.753 1.753H5.753A1.753 1.753 0 014 28.647V3.153C4 2.185 4.785 1.4 5.753 1.4zm21.043 1.686H5.753v25.63h21.043V3.087z\"/><path d=\"M9.194 6.4h12.374v1.814H9.194V6.4zM9.01 9.752h9.845v1.814H9.009V9.752zM9.01 12.752h6.845v1.814H9.009v-1.814zM20.129 13.023l3.252 3.253a.353.353 0 010 .498l-8.255 8.25-3.346.371a.701.701 0 01-.775-.775l.37-3.347 8.256-8.25a.353.353 0 01.498 0zm5.46-.452l-1.76-1.76a1.41 1.41 0 00-1.99 0l-1.276 1.277a.353.353 0 000 .498l3.252 3.252c.137.138.36.138.498 0l1.276-1.276a1.41 1.41 0 000-1.99z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});